import { clientWithToken } from "@/helpers/axiosHelper";
import { getDistricts } from "../../helpers/generalHelper";

export default {
    async getCities(context, payload) {
        await clientWithToken.post('Definition/GetCities', { Skip: 0, Take: 100, SearchTerm: null }).then(res => {
            context.commit('cityCommit', res.data.Data.Items.sort((a, b) => {
                var textA = a.Title.toUpperCase();
                var textB = b.Title.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            }))
        })
    },
    async getCity(context, payload) {
        return await clientWithToken.post('Definition/GetCity', payload).then(res => {
            return res.data
        })
    },
    async getDistrict(context, payload) {
        if (payload === null) return
        const districts = await getDistricts(payload)
        context.commit("districtCommit", districts)
        return districts;
    }
}
