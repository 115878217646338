export default {
	userState: (state) => state.user,
	userLoading: (state) => state.loading,
	loginAsSupplier: (state) => state.loginAsSupplier,
	activeRegister: (state) => state.activeRegister,
	companyResponse: (state) => state.companyResponse,
	getToken: (state) => state.token,
	getWareHouses: (state) => state.warehouses,
	getDeliveryCompanies: (state) => state.deliveryCompanies,
	getInvoiceInfo: (state) => state.invoiceInfo,
	vehicleResponse: (state) => state.vehicleResponse,
	warehouseId: (state) => state.warehouseId,
	depotResponse: (state) => state.depotResponse,
	bankResponse: (state) => state.bankResponse,
	changePasswordResponse: (state) => state.changePasswordResponse,
	resetPasswordResponse: (state) => state.resetPasswordResponse,
	invoiceResponse: (state) => state.invoiceResponse,
	loginResponse: (state) => state.loginResponse,
	userResponse: (state) => state.userResponse,
	invoceResponse: (state) => state.invoceResponse,
	forgotPasswordResponse: (state) => state.forgotPasswordResponse,
	inviteFriendResponse: (state) => state.inviteFriendResponse,
	confirmResetKeyResponse: (state) => state.confirmResetKeyResponse,
	signUpUserInfo: (state) => state.signUpUserInfo,
	dashboardStatistics: (state) => state.dashboardStatistics,
	policies: (state) => state.policies,
	systemSettings: (state) => state.systemSettings,
	closableAdverts: (state) => state.closableAdverts,
	closableAdvertsLength: (state) => state.closableAdvertsLength,
}
