import router from '@/router/index'
import { clientWithToken, updateToken } from '../../helpers/axiosHelper'
import { isMobile } from '../../helpers/generalHelper'
import setToken from '../../messaging_get_token'

const routeHandlerAfterAuthSuccess = () => {
	console.log(router.currentRoute.name)
	switch (router.currentRoute.name) {
		case 'homedashboard':
			router.go(0) // reload
			console.log('home')
			break

		case 'ilanlar.aktif':
			// router.go(0) // reload
			console.log(router.currentRoute)
			console.log('ilanlar')
			break

		default: // redirect home
			router.push({ name: 'homedashboard' })
			console.log('default redirect')
			break
	}
	return
}

// start broadcast channel
const roleSwitchChannel = new BroadcastChannel('roleSwitch')

// listen channel messages
roleSwitchChannel.onmessage = (event) => {
	window.location.reload()
}

export default {
	async getUser(context, payload) {
		await clientWithToken
			.post('/Account/Get')
			.then((res) => {
				if (res.data.HasError) {
					window.sendNotification.error(res.data.Message)
				} else {
					var user = {
						...res.data.Data,
					}
					user.Role === 5 ? (user.IsCarrier = true) : user.Role === 6 ? (user.IsCarrier = false) : null
					context.commit('userCommit', user)
				}
			})
			.catch((err) => {})
	},

	async signInUser(context, payload = {}) {
		return await clientWithToken.post('/Login/SignInWithEmail', payload).then(async (res) => {
			if (res.data.HasError) {
				window.sendNotification.error(res.data.Message)
			} else {
				if (res.data.Data && res.data.Data.Token) {
					localStorage.setItem('token', res.data.Data.Token)
					updateToken()
					if (!isMobile()) this.commit('setToken', res.data.Data.Token)

					// router.push({
					//   path: "/anasayfa",
					// });
				} else {
					return false
				}
				context.commit('loginResponse', res.data)
				routeHandlerAfterAuthSuccess()
			}
		})
	},

	async confirmAccountSignIn(context, payload = {}) {
		var res = await clientWithToken.post('/Login/ConfirmAccount', payload)

		if (res.data.HasError) {
			// context.commit("loginResponse", res.data)
			window.sendNotification.error(res.data.Message)
		} else {
			localStorage.setItem('token', res.data.Data.Token)
			updateToken()
			this.commit('toggleLoginDialog', false)

			if (!isMobile()) this.commit('setToken', res.data.Data.Token)

			routeHandlerAfterAuthSuccess()
			// Call getUser to retrieve the user's data and update the store
			await context.dispatch('getUser')

			// Reset login as supplier val
			this.commit('loginAsSupplier', false)

			// if (router.path === '/anasayfa') {
			//   router.go() // Sayfayı yeniden yükler
			// } else {
			//   router.push({ path: '/anasayfa' }) // Yeni sayfaya yönlendirir
			// }

			window.sendNotification.success(res.data.Message)
			console.log('login with success')
		}

		return res
	},

	async signUpUser(context, payload = {}) {
		await clientWithToken
			.post(payload.isCarrier ? 'Login/RegisterAsCarrieer' : 'Login/RegisterAsSupplierQuery', payload.Form)
			.then(async (res) => {
				if (res.data.HasError) {
					window.sendNotification.error(res.data.Message)
					context.commit('signUpUserInfo', res.data)
				} else {
					context.commit('signUpUserInfo', res.data)
					// localStorage.setItem('token', res.data.Data.Token)
					// updateToken()
					// if (!isMobile()) this.commit('setToken', res.data.Data.Token)
					context.commit('activeRegister', context.getters['activeRegister'] + 1)
					// window.sendNotification.success('Başarı ile kayıt oldunuz')

					// routeHandlerAfterAuthSuccess()
					// Call getUser to retrieve the user's data and update the store
					//await context.dispatch('getUser')

					// router.push({
					//   path: "/anasayfa",
					// });
				}
			})
	},
	async signOutUser(context, payload = {}) {
		localStorage.removeItem('token')
		updateToken(null)
		context.commit('removeUser')
		router.push({
			path: '/',
		})
	},
	async addBillDetails(context, payload = {}) {
		await clientWithToken.post('/Account/UpdateInvoiceInfo', payload).then((res) => {
			if (res.data.HasError) {
				window.sendNotification.error(res.data.Message)
			} else {
				window.sendNotification.success(res.data.Message)
				context.commit('setInvoiceInfo', res.data.Data.InvoiceInfo)
				router.push({
					name: 'homedashboard',
				})
			}
			context.commit('companyResponse', res.data)
		})
	},

	async getWarehouses(context) {
		await clientWithToken
			.post('/Warehouse/List', {
				Id: null,
				SearchTerm: null,
			})
			.then((res) => {
				if (res.data.HasError) {
					window.sendNotification.error(res.data.Message)
				} else {
					context.commit('setWarehouses', res.data.Data.Items)
				}
			})
	},

	async getDeliveryCompanies(context) {
		await clientWithToken
			.post('/DeliveryCompany/List', {
				Id: null,
				SearchTerm: null,
			})
			.then((res) => {
				if (res.data.HasError) {
					window.sendNotification.error(res.data.Message)
				} else {
					context.commit('setDeliveryCompanies', res.data.Data.Items)
				}
			})
	},
	async getUserVehicles(context, payload = {}) {
		await clientWithToken.post('/Vehicle/List', payload).then((res) => {
			if (res.data.HasError) {
				window.sendNotification.error(res.data.Message)
			} else {
				context.commit('setCarreerVehicles', res.data.Data.Items)
			}
		})
	},
	async changePassword(context, payload = {}) {
		await clientWithToken.post('/Account/ChangePassword', payload).then((res) => {
			if (res.data.HasError) {
				window.sendNotification.error(res.data.Message)
			} else {
				context.dispatch('signOutUser')
				context.commit('setPasswordDialog', false)
				window.sendNotification.success(res.data.Message)
			}
			context.commit('changePasswordResponse', res.data)
		})
	},
	async verifyOtp(context, payload = {}) {
		await clientWithToken.post('/Account/ConfirmPhoneNumber', payload).then((res) => {})
	},

	async updateNotificationSettingsCareer(context, payload = {}) {
		await clientWithToken.post('/Account/UpdateNotificationSettingsCareer', payload).then((res) => {
			if (res.data.HasError) {
				window.sendNotification.error(res.data.Message)
			} else {
				window.sendNotification.success(res.data.Message)
				context.commit('setNotificationSettings', res.data.Data.NotificationSettings)
			}
		})
	},

	async inviteFriend(context, payload = {}) {
		await clientWithToken.post('Account/InviteFriend', payload).then((res) => {
			if (res.data.HasError) {
				window.sendNotification.error(res.data.Message)
			} else {
				window.sendNotification.success(res.data.Message)
			}
			context.commit('inviteFriendResponse', res.data)
		})
	},

	async deleteVehicle(context, payload = {}) {
		await clientWithToken.post('/Vehicle/Delete', payload).then((res) => {
			if (res.data.HasError) {
				window.sendNotification.error(res.data.Message)
			} else {
				window.sendNotification.success(res.data.Message)
				context.dispatch('getUserVehicles')
			}
		})
	},

	async updateVehicle(context, payload = {}) {
		payload.VehicleForm.VehicleId = payload.VehicleForm.ID
		return await clientWithToken
			.post(
				payload.VehicleForm.Type === 0
					? '/Vehicle/AddUpdateTruck'
					: payload.VehicleForm.Type === 1
					? '/Vehicle/AddUpdateLorry'
					: '/Vehicle/AddUpdatePickUp',
				payload.VehicleForm
			)
			.then((res) => {
				if (res.data.HasError) {
					window.sendNotification.error(res.data.Message)
				} else {
					window.sendNotification.success(res.data.Message)
					context.dispatch('getUserVehicles')
				}
				context.commit('vehicleResponse', res.data)
				return res.data
			})
	},

	async deleteWarehouse(context, payload = {}) {
		await clientWithToken
			.post(payload.isWarehouse ? '/warehouse/delete' : '/deliverycompany/delete', payload.Id)
			.then((res) => {
				context.commit('warehouseId', null)
				if (res.data.HasError) {
					window.sendNotification.error(res.data.Message)
				} else {
					window.sendNotification.success(res.data.Message)
					payload.isWarehouse ? context.dispatch('getWarehouses') : context.dispatch('getDeliveryCompanies')
				}
			})
	},

	async updateWarehouse(context, payload = {}) {
		return await clientWithToken
			.post(payload.isWarehouse ? '/Warehouse/AddUpdate' : '/DeliveryCompany/AddUpdate', {
				...payload.WarehouseForm,

				Lat: payload.lat,
				Lon: payload.lng,
			})
			.then((res) => {
				if (res.data.HasError) {
					window.sendNotification.error(res.data.Message)
				} else {
					window.sendNotification.success(res.data.Message)
					payload.isWarehouse ? context.dispatch('getWarehouses') : context.dispatch('getDeliveryCompanies')
				}
				return res.data
			})
	},

	async updateBankAccountInfo(context, payload = {}) {
		await clientWithToken.post('/Account/UpdateBankAccountInfo', payload).then((res) => {
			if (res.data.HasError) {
				window.sendNotification.error(res.data.Message)
			} else {
				window.sendNotification.success('Banka Hesap Bilgileriniz Güncellenmiştir')
				context.commit('setBankAccount', res.data.Data.BankAccount)
			}
			context.commit('bankResponse', res.data)
		})
	},

	async updateUserInfo(context, payload = {}) {
		await clientWithToken.post('/Account/UpdateAccount', payload).then((res) => {
			if (res.data.HasError) {
				window.sendNotification.error(res.data.Message)
			} else {
				window.sendNotification.success('Hesap Bilgileriniz Güncellenmiştir')
				context.dispatch('getUser')
			}
			context.commit('userResponse', res.data)
		})
	},
	async sendReview(context, payload = {}) {
		await clientWithToken.post('/Account/AddReview', payload).then((res) => {
			if (res.data.HasError) {
				window.sendNotification.error(res.data.Message)
			} else {
				window.sendNotification.success('Görüş ve öneriniz kaydedilmiştir')
				context.dispatch('getUser')
			}
			context.commit('userResponse', res.data)
		})
	},
	async updateWorkCitiesInfo(context, payload = {}) {
		await clientWithToken.post('/Account/UpdateWorkCities', payload).then((res) => {
			if (res.data.HasError) {
				window.sendNotification.error(res.data.Message)
			} else {
				window.sendNotification.success('Şehir Güzergah Bilgileriniz Güncellenmiştir')
				context.dispatch('getUser')
			}
			context.commit('userResponse', res.data)
		})
	},
	async updateInvoiceInfo(context, payload = {}) {
		return await clientWithToken.post('/Account/UpdateInvoiceInfo', payload).then((res) => {
			if (res.data.HasError) {
				window.sendNotification.error(res.data.Message)
			} else {
				window.sendNotification.success(res.data.Message)
				context.dispatch('getUser')
			}
			context.commit('invoiceResponse', res.data)
			return res.data
		})
	},

	async getDashboardStatistics(context, payload = {}) {
		const isCarrier = context.getters['userState'].IsCarrier
		await clientWithToken
			.post('/Account/' + (isCarrier ? 'GetCarrierHomePageStatistics' : 'GetSupplierHomePageStatistics'), {})
			.then((res) => {
				if (res.data.HasError) {
					// window.sendNotification.error(res.data.Message);
				} else {
					context.commit('dashboardStatistics', res.data.Data)
				}
			})
	},

	async blockUser(context, payload = {}) {
		await clientWithToken.post('/Account/BlockUser', payload).then((res) => {
			if (res.data.HasError) {
				// window.sendNotification.error(res.data.Message);
			} else {
				context.dispatch('getUser')
				window.sendNotification.success(res.data.Message)
				router.push({
					path: '/ilanlar/aktif',
				})
			}
		})
	},

	async removeBlock(context, payload = {}) {
		await clientWithToken.post('/Account/BlockUser', payload).then((res) => {
			if (res.data.HasError) {
				// window.sendNotification.error(res.data.Message);
			} else {
				context.dispatch('getUser')
				window.sendNotification.success(res.data.Message)
			}
		})
	},

	async forgotPasswordRequest(context, payload = {}) {
		await clientWithToken.post('/Login/SendForgotPasswordSms', payload).then((res) => {
			if (res.data.HasError) {
				window.sendNotification.error(res.data.Message)
				context.commit('forgotPasswordResponse', res.data)
			} else {
				context.commit('forgotPasswordResponse', res.data.Data)
				window.sendNotification.success(res.data.Message)
			}
		})
	},

	async checkForgotPassword(context, payload = {}) {
		await clientWithToken.post('/Login/CheckForgotPasswordCode', payload).then((res) => {
			if (res.data.HasError) {
				context.commit('confirmResetKeyResponse', res.data)
			} else {
				window.sendNotification.success('Doğrulandı!')
				context.commit('confirmResetKeyResponse', res.data.Data)
			}
		})
	},

	async resetPassword(context, payload = {}) {
		await clientWithToken.post('/Login/ResetPassword', payload).then((res) => {
			if (res.data.HasError) {
				window.sendNotification.error(res.data.Message)
				context.commit('resetPasswordResponse', res.data)
			} else {
				context.commit('resetPasswordResponse', res.data.Data)
				window.sendNotification.success(res.data.Message)
				localStorage.setItem('token', res.data.Data.Token)
				updateToken()
				router.push({
					path: '/anasayfa',
				})
			}
		})
	},

	async checkInvoiceInfo(context, payload = {}) {
		return await clientWithToken.get('/Account/CheckInvoiceInfo').then((res) => {
			if (res.data.HasError) {
				window.sendNotification.error('İlan oluşturulurken bir hata oluştu!')
			} else {
				return res.data
			}
		})
	},

	async sendConfirmationSMS(context, payload = {}) {
		return await clientWithToken.post('/Account/SendConfirmationSMS', payload).then(async (res) => {
			if (res.data.HasError) {
				window.sendNotification.error(res.data.Message)
			} else {
				window.sendNotification.success(res.data.Message)
			}
			return !res.data.HasError
		})
	},

	async confirmPhoneNumber(context, payload = {}) {
		return await clientWithToken.post('/Account/ConfirmPhoneNumber', payload).then(async (res) => {
			if (res.data.HasError) {
				window.sendNotification.error(res.data.Message)
			} else {
				window.sendNotification.success(res.data.Message)
			}
			return !res.data.HasError
		})
	},

	async getPolicies(context, payload = {}) {
		await clientWithToken.post('/Definition/GetPolicies', {}).then(async (res) => {
			if (res.data.HasError) {
				// window.sendNotification.error(res.data.Message);
			} else {
				context.commit('policies', res.data.Data)
			}
		})
	},

	async getSystemSettings(context) {
		await clientWithToken.get('/Definition/GetSystemSettings').then(async (res) => {
			if (!res.data.HasError) context.commit('systemSettings', res.data.Data.SystemSetting)
		})
	},

	async registerDevice(context, payload = {}) {
		await clientWithToken.post('/Account/RegisterDevice', payload).then(async (res) => {})
	},

	async approvePolicies(context, payload = {}) {
		return await clientWithToken.get('/Account/ApprovePolicies', payload).then(async (res) => true)
	},

	async switchRole(context, payload = {}) {
		return await clientWithToken
			.get('/Account/ToggleCurrentRole')
			.then(async (res) => {
				if (res.data.HasError) {
					window.sendNotification.error(res.data.Message)
					return false
				} else {
					localStorage.removeItem('token')
					updateToken(null)
					context.commit('removeUser')
					if (res.data.Data) {
						localStorage.setItem('token', res.data.Data)
						updateToken()
						if (!isMobile()) this.commit('setToken', res.data.Data.Token)
						context.dispatch('getUser')
						window.sendNotification.success(res.data.Message)

						// sync tabs here
						roleSwitchChannel.postMessage('changed')

						return true
					} else {
						window.sendNotification.error('Rol değiştirme başarısız oldu!')
						return false
					}
				}
			})
			.catch((err) => {
				window.sendNotification.error('Rol değiştirme başarısız oldu!')
				return false
			})
	},

	async updateUserLocation(context, payload = {}) {
		context.commit('selfLoading', true)
		return await clientWithToken
			.post('/Account/UpdateMyLocation', payload)
			.then(async (res) => {
				if (res.data.HasError) {
					return false
				} else {
					return true
					//
				}
			})
			.catch((err) => {
				return false
			})
			.finally(() => context.commit('selfLoading', false))
	},
	//Dealer requests
	async getDealerInfo(context, payload = {}) {
		await clientWithToken
			.post('/Dealer/GetDealerInfo')
			.then((res) => {
				if (res.data.HasError) {
					window.sendNotification.error(res.data.Message)
				} else {
					var user = {
						...res.data.Data,
					}
					user.Role === 5 ? (user.IsCarrier = true) : user.Role === 6 ? (user.IsCarrier = false) : null
					context.commit('userCommit', user)
				}
			})
			.catch((err) => {})
	},

	async pollAdverts(context) {
		await context.commit('hideLoader', true)
		await clientWithToken
			.post('/Account/IsThereOpenAdvert')
			.then(async (res) => {
				if (!res.data.HasError) {
					const { TotalCount, Items } = res.data.Data
					await context.commit('setClosableAdverts', Items)
					await context.commit('setClosableAdvertsLength', TotalCount)
				}
			})
			.catch((err) => {})
			.finally(() => context.commit('hideLoader', false))
	},

	async closeAdvert(context, payload) {
		// await context.commit('hideLoader', true) /* loader gizlemek için açın */
		const res = await clientWithToken.post('/Account/CloseAdvert', payload)
		if (res.data.HasError) {
			window.sendNotification.error(res.data.Message)
		} else {
			window.sendNotification.success(res.data.Message)
		}
		// await context.commit('hideLoader', false) /* loader gizlemek için açın */
		return !res.data.HasError
	},

	async getCarrierVideo(context) {
		const res = await clientWithToken.post('/Account/GetCarrierTutorialVideo')
		return !res.data.HasError ? res.data.Data : null
	},
}
