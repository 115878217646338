import router from '@/router/index'
import { clientWithToken, updateToken } from '../../helpers/axiosHelper'
import { isMobile } from '../../helpers/generalHelper'
import setToken from '../../messaging_get_token'

const routeHandlerAfterAuthSuccess = () => {
	console.log(router.currentRoute.name)
	switch (router.currentRoute.name) {
		case 'homedashboard':
			router.go(0) // reload
			console.log('home')
			break

		case 'ilanlar.aktif':
			// router.go(0) // reload
			console.log(router.currentRoute)
			console.log('ilanlar')
			break

		default: // redirect home
			router.push({ name: 'homedashboard' })
			console.log('default redirect')
			break
	}
	return
}

// start broadcast channel
const roleSwitchChannel = new BroadcastChannel('roleSwitch')

// listen channel messages
roleSwitchChannel.onmessage = (event) => {
	window.location.reload()
}

export default {
	async getDealer(context, payload) {
		console.log('GetDealer isteÄŸi gÃ¶nderiliyor')
		await clientWithToken
			.post('/Dealer/GetDealerInfo')
			.then((res) => {
				if (res.data.HasError) {
					console.log('GetDealerInfo isteÄŸi hata dÃ¶ndÃ¼rdÃ¼!' + res.data.Message)
					window.sendNotification.error(res.data.Message)
				} else {
					console.log('GetDealerInfo isteÄŸi baÅŸarÄ±yla cevaplandÄ±! Data > ' + res.data)
					/* var dealerInfo = {
                ...res.data.Data,
              }; */
					//user.Role === 5 ? (user.IsCarrier = true) : user.Role === 6 ? (user.IsCarrier = false) : null;
					//console.log("DealerInfo :" +dealerInfo);
					context.commit('setDealerData', res.data.Data)
				}
			})
			.catch((err) => {})
	},

	async inviteDealer(context, payload = {}) {
		await clientWithToken.post('Dealer/InviteDealer', payload).then((res) => {
			if (res.data.HasError) {
				window.sendNotification.error(res.data.Message)
			} else {
				window.sendNotification.success(res.data.Message)
			}
			context.commit('inviteDealerResponse', res.data)
		})
	},

	async getInviteLink(context, payload = {}) {
		await clientWithToken.post('Dealer/GetInviteLink', payload).then((res) => {
			if (res.data.HasError) {
				window.sendNotification.error(res.data.Message)
			} else {
				context.commit('inviteLinkResponse', res.data.Data)
			}
		})
	},

	async getInvoices(context, payload = {}) {
		await clientWithToken.post('Dealer/GetInvoices', payload).then((res) => {
			if (res.data.HasError) {
				window.sendNotification.error(res.data.Message)
			} else {
				context.commit('setInvoices', res.data.Data)
			}
		})
	},

	async uploadInvoice(context, payload = {}) {
		const formData = new FormData()
		formData.append('file', payload.file)

		console.log(payload)

		await clientWithToken
			.post('Definition/UploadFile', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				params: {
					index: 0,
					uploadtype: 'blog',
				},
			})
			.then(async (res) => {
				if (res.data.HasError) {
					window.sendNotification.error(res.data.Message)
				} else {
					const res = await this.updateInvoiceLink({
						InvoiceId: payload.invoice.ID,
						FileUrl: res.data.Data[0].Url,
					})
					if (res) {
						const invoice = context.state.invoices.find((i) => i.Id === payload.Id)
						invoice.InvoiceFileUrl = payload.FileUrl
						window.sendNotification.success('Fatura yüklendi')
					} else {
						window.sendNotification.error('Fatura yüklenirken bir hata oluştu')
					}
				}
			})
	},

	async updateInvoiceLink(context, payload = {}) {
		await clientWithToken.post('Dealer/UpdateInvoiceFile', payload).then((res) => {
			return !res.data.HasError
		})
	},
}
