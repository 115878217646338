import Vue from 'vue'
import Vuex from 'vuex'
import User from './user'
import cityDistrict from './cityDistrict'
import naku from './naku'
import trailers from './trailers'
import Adverts from './adverts'
import Bids from './Bids'
import Dealer from './dealer'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
	modules: {
		User,
		cityDistrict,
		naku,
		trailers,
		Adverts,
		Bids,
		Dealer,
	},
	state: {
		howToUseDialog: false,
		mapService: null,
		openEditAd: '',
		loading: false,
		selfLoading: false,
		hideLoader: false,
		currentImage: true,
		nativeWHMapDialog: {
			lat: null,
			lng: null,
			warehouseMapDialog: false,
		},
		nativeCMapDialog: {
			lat: null,
			lng: null,
			companyMapDialog: false,
		},
		nativeTrackMapDialog: {
			center: { lat: 45.508, lng: -73.587 },
			marker: {
				position: {
					lat: 45.508,
					lng: -73.587,
				},
			},
			trackMapDialog: false,
			locationInterval: null,
		},
	},
	mutations: {
		howToUseDialog(state, data) {
			state.howToUseDialog = data
		},
		openEditAd(state, data) {
			state.openEditAd = data
		},
		loading(state, data) {
			state.loading = data
		},
		selfLoading(state, data) {
			state.selfLoading = data
		},
		hideLoader(state, data) {
			state.hideLoader = data
		},
		currentImage(state, data) {
			state.currentImage = data
		},
		nativeWHMapDialog(state, data) {
			state.nativeWHMapDialog = { ...state.nativeWHMapDialog, ...data }
		},
		nativeCMapDialog(state, data) {
			state.nativeCMapDialog = { ...state.nativeCMapDialog, ...data }
		},
		nativeTrackMapDialog(state, data) {
			state.nativeTrackMapDialog = { ...state.nativeTrackMapDialog, ...data }
		},
	},
	actions: {},
	getters: {
		howToUseDialog: (state) => state.howToUseDialog,
		openEditAd: (state) => state.openEditAd,
		loading: (state) => state.loading,
		selfLoading: (state) => state.selfLoading,
		hideLoader: (state) => state.hideLoader,
		currentImage: (state) => state.currentImage,
		nativeWHMapDialog: (state) => state.nativeWHMapDialog,
		nativeCMapDialog: (state) => state.nativeCMapDialog,
		nativeTrackMapDialog: (state) => state.nativeTrackMapDialog,
	},
	strict: debug,
})
