export default {
    nakuCommit(state, payload) {
        state.packets = payload
    },
    nakuHistory(state, payload) {
        state.nakuHistory = payload
    },
    iyzicoPaymentDialog(state, payload) {
        state.iyzicoPaymentDialog = payload
    },
    ThreedHtml(state, payload) {
        state.ThreedHtml = payload
    },
    paymentForm(state, payload) {
        state.paymentForm = payload
    },
    buyNakuResponse(state, data) {
        state.buyNakuResponse = data
    },
}