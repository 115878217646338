<template>
  <div class="authincation" :class="fromDialog ? 'dialog' : 'h-100'">
    <div class="container color">
      <div class="row justify-content-center align-items-center" :class="fromDialog ? '' : 'h-100'">
        <div class="col-9">
          <div class="authincation-content">
            <div class="row no-gutters">
              <div class="col-xl-12">
                <div class="auth-form">
                  <div class="text-center mb-3">
                    <a class="logo" href="javascript:;" @click="() => this.$router.push({ path: '/' })"><img class="logo animate__animated animate__zoomInDown" src="../../assets/images/whitelogo.svg" alt=""/></a>
                  </div>
                  <div class="mb-3">
                    <label class="mb-1">Telefon</label>
                    <el-input
                    class="form-control"
                    :class="{
                      'input-validation-error': loginResponse.ValidationErrors.some((x) => x.Key == 'PhoneNumber'),
                    }"
                    v-mask="['+90 (###) ### ## ##']"
                    placeholder="+90 (xxx) xxx xx xx"
                    v-model="loginForm.PhoneNumber"
                  ></el-input>
                    <span class="validation-error" v-if="loginResponse.ValidationErrors.some((x) => x.Key == 'PhoneNumber')">{{ loginResponse.ValidationErrors.filter((x) => x.Key == "PhoneNumber")[0].Value }}</span>
                  </div>
                  <!-- <div class="mb-3">
                    <label class="mb-1">Şifre</label>
                    <el-input
                      v-model="loginForm.Password"
                      type="password"
                      placeholder="******"
                      class="form-control"
                      :class="{
                        'input-validation-error': loginResponse.ValidationErrors.some((x) => x.Key == 'Password'),
                      }"
                      value="Password"
                      @keypress.enter.native="signIn"
                    />
                    <span class="validation-error" v-if="loginResponse.ValidationErrors.some((x) => x.Key == 'Password')">{{ loginResponse.ValidationErrors.filter((x) => x.Key == "Password")[0].Value }}</span>
                  </div> -->
                  <div
                    class="
                      row
                      d-flex
                      justify-content-between
                      mt-4
                      mb-2
                      animate__animated animate__bounceInUp
                    "
                  >
                    <div class="mb-3">
                      <div class="form-check custom-checkbox ms-1">
                        <input type="checkbox" v-model="rememberMe" class="form-check-el-input" id="basic_checkbox_1" />
                        <label class="form-check-label" for="basic_checkbox_1">Beni Hatırla</label>
                      </div>
                    </div>
                    <!-- <div class="mb-3">
                      <a href="javascript:;" @click="handleForgotPassword">Şiremi Unuttum</a>
                    </div> -->
                  </div>
                  <div class="text-center animate__animated animate__bounceInUp">
                    <button type="submit" class="btn btn-primary btn-block" @click="signIn">
                      Giriş
                    </button>
                  </div>
                  <div
                    class="
                      new-account
                      mt-3
                      animate__animated animate__bounceInUp
                    "
                  >
                    <p>
                      Hesabın yok mu?
                      <a href="javascript:;" class="text-primary" @click="register">Ücretsiz Kayıt Ol </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" class="signOutDialog" title="Telefon Numarası Onayı" :visible.sync="forgotPasswordDialog" :modalAppendToBody="false">
      <span>Cep telefonunuza özel 4 haneli bir şifre gönderilecektir. Lütfen aktif olarak kullandığınız telefon numaranızı giriniz.</span>
      <el-input
        class="w-100 bg-light form-control py-0 mt-3"
        :class="{
          'input-validation-error': forgotPasswordResponse.ValidationErrors ? forgotPasswordResponse.ValidationErrors.some((x) => x.Key == 'PhoneNumber') : '',
        }"
        v-mask="['+90 (###) ### ## ##']"
        placeholder="+90 (xxx) xxx xx xx"
        v-model="forgotPasswordForm.PhoneNumber"
      ></el-input>
      <span class="validation-error" v-if="forgotPasswordResponse.ValidationErrors && forgotPasswordResponse.ValidationErrors.some((x) => x.Key == 'PhoneNumber')">{{ forgotPasswordResponse.ValidationErrors && forgotPasswordResponse.ValidationErrors.filter((x) => x.Key == "PhoneNumber")[0].Value }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button class="continue-button" :loading="loading" @click="handleForgotPasswordRequest">Devam Et</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="signOutDialog" title="Telefon Numarası Onayı" :visible.sync="confirmResetKeyDialog" :modalAppendToBody="false">
      <span>Cep telefonunuza özel 4 haneli bir şifre gönderilecektir. Lütfen aktif olarak kullandığınız telefon numaranızı giriniz.</span>
      <div class="otp-wrapper">
        <div class="input-row otp-row" @keydown="handleKeydown">
          <el-input
            ref="otp1"
            placeholder="..."
            v-model="otp1"
            :class="{
              'input-validation-error': confirmResetKeyResponse.ValidationErrors ? confirmResetKeyResponse.ValidationErrors.some((x) => x.Key == 'Code') : '',
            }"
            :maxlength="1"
          ></el-input>

          <el-input
            ref="otp2"
            placeholder="..."
            v-model="otp2"
            :class="{
              'input-validation-error': confirmResetKeyResponse.ValidationErrors ? confirmResetKeyResponse.ValidationErrors.some((x) => x.Key == 'Code') : '',
            }"
            :maxlength="1"
          ></el-input>
          <el-input
            ref="otp3"
            placeholder="..."
            v-model="otp3"
            :class="{
              'input-validation-error': confirmResetKeyResponse.ValidationErrors ? confirmResetKeyResponse.ValidationErrors.some((x) => x.Key == 'Code') : '',
            }"
            :maxlength="1"
          ></el-input>
          <el-input
            ref="otp4"
            placeholder="..."
            v-model="otp4"
            :class="{
              'input-validation-error': confirmResetKeyResponse.ValidationErrors ? confirmResetKeyResponse.ValidationErrors.some((x) => x.Key == 'Code') : '',
            }"
            :maxlength="1"
          ></el-input>
        </div>
      </div>
      <span class="validation-error" v-if="confirmResetKeyResponse.ValidationErrors && confirmResetKeyResponse.ValidationErrors.some((x) => x.Key == 'Code')">{{ confirmResetKeyResponse.ValidationErrors && confirmResetKeyResponse.ValidationErrors.filter((x) => x.Key == "Code")[0].Value }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button class="continue-button" :loading="loading" @click="handleCheckForgotPasswordRequest">Onayla</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="signOutDialog" title="Yeni Şifre Oluşturma" :visible.sync="resetPasswordDialog" :modalAppendToBody="false">
      <span>Lütfen alttaki kutulara yeni şifrenizi giriniz.(Not almanızı öneririz).</span>
      <el-input
        class="form-control mb-3 mt-3"
        :class="{
          'input-validation-error': resetPasswordResponse.ValidationErrors ? resetPasswordResponse.ValidationErrors.some((x) => x.Key == 'Password') : '',
        }"
        placeholder="******"
        name="currentPassword"
        v-model="resetPasswordForm.Password"
        type="password"
      ></el-input>
      <span class="validation-error" v-if="resetPasswordResponse.ValidationErrors && resetPasswordResponse.ValidationErrors.some((x) => x.Key == 'Password')">{{ resetPasswordResponse.ValidationErrors && resetPasswordResponse.ValidationErrors.filter((x) => x.Key == "Password")[0].Value }}</span>
      <el-input
        class="form-control"
        :class="{
          'input-validation-error': resetPasswordResponse.ValidationErrors ? resetPasswordResponse.ValidationErrors.some((x) => x.Key == 'PasswordRetry') : '',
        }"
        placeholder="******"
        name="newpassword"
        v-model="resetPasswordForm.PasswordRetry"
        type="password"
      ></el-input>
      <span class="validation-error" v-if="resetPasswordResponse.ValidationErrors && resetPasswordResponse.ValidationErrors.some((x) => x.Key == 'PasswordRetry')">{{ resetPasswordResponse.ValidationErrors && resetPasswordResponse.ValidationErrors.filter((x) => x.Key == "PasswordRetry")[0].Value }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button class="continue-button" :loading="loading" @click="handleResetPasswordRequest">Devam Et</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="signOutDialog" title="Doğrulama Kodu" :visible.sync="confirmAccountDialog" :modalAppendToBody="false">
      <span>Hesabınıza giriş yapmak için cep telefonunuza özel 4 haneli bir onay kodu gönderilmiştir. Onay kodunuzu giriniz.</span>
      <div class="otp-wrapper">
        <div class="input-row otp-row" @keydown="handleKeydownC">
          <el-input ref="otpC1" placeholder="..." v-model="otpC1" :maxlength="1"></el-input>
          <el-input ref="otpC2" placeholder="..." v-model="otpC2" :maxlength="1"></el-input>
          <el-input ref="otpC3" placeholder="..." v-model="otpC3" :maxlength="1"></el-input>
          <el-input ref="otpC4" placeholder="..." v-model="otpC4" :maxlength="1"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="!loading" :loading="loading" class="bg-transparent" @click="signIn">
          <span>Tekrar Gönder</span>
        </el-button>

        <el-button :loading="loading" class="px-5 py-3" type="primary" @click="handleConfirmAccount">Onayla</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import META_DATA from "../../constants/metaData";

export default {
  metaInfo() {
    return {
      title: META_DATA.LOGIN.Title,
    };
  },
  data() {
    return {
      loginForm: {
        PhoneNumber: ""
      },
      forgotPasswordForm: {
        PhoneNumber: null,
      },
      otp: "",
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otpC1: "",
      otpC2: "",
      otpC3: "",
      otpC4: "",
      resetPasswordForm: {
        PhoneNumber: null,
        ResetKey: null,
        Password: null,
        PasswordRetry: null,
      },
      forgotPasswordDialog: false,
      confirmResetKeyDialog: false,
      resetPasswordDialog: false,
      confirmAccountDialog: false,
      rememberMe: false,
      ConfirmAccountCode: null,
    };
  },
  props: {
    fromDialog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    loginResponse() {
      return this.$store.getters["loginResponse"];
    },
    forgotPasswordResponse() {
      return this.$store.getters["forgotPasswordResponse"];
    },
    confirmResetKeyResponse() {
      return this.$store.getters["confirmResetKeyResponse"];
    },
    resetPasswordResponse() {
      return this.$store.getters["resetPasswordResponse"];
    },
    loading() {
      return this.$store.getters["loading"];
    },
  },
  watch: {
    confirmResetKeyDialog: function() {
      if (this.confirmResetKeyDialog) {
        this.$nextTick(() => {
          this.$refs.otp1.focus();
        });
      }
    },
    otp1: function() {
      if (this.otp1 != null && this.otp1.length == 1) {
        this.$refs.otp2.focus();
      } else {
        this.$refs.otp1.focus();
      }
    },
    otp2: function() {
      if (this.otp2 != null && this.otp2.length == 1) {
        this.$refs.otp3.focus();
      } else {
        this.$refs.otp1.focus();
      }
    },
    otp3: function() {
      if (this.otp3 != null && this.otp3.length == 1) {
        this.$refs.otp4.focus();
      } else {
        this.$refs.otp2.focus();
      }
    },
    otp4: function() {
      if (this.otp4 != null && this.otp4.length == 1) {
        this.handleCheckForgotPasswordRequest();
      } else {
        this.$refs.otp3.focus();
      }
    },
    otpC1: function() {
      if (this.otpC1 != null && this.otpC1.length == 1) {
        this.$refs.otpC2.focus();
      } else {
        this.$refs.otpC1.focus();
      }
    },
    otpC2: function() {
      if (this.otpC2 != null && this.otpC2.length == 1) {
        this.$refs.otpC3.focus();
      } else {
        this.$refs.otpC1.focus();
      }
    },
    otpC3: function() {
      if (this.otpC3 != null && this.otpC3.length == 1) {
        this.$refs.otpC4.focus();
      } else {
        this.$refs.otpC2.focus();
      }
    },
    otpC4: function() {
      if (this.otpC4 != null && this.otpC4.length == 1) {
        this.handleConfirmAccount();
      } else {
        this.$refs.otpC3.focus();
      }
    },
    forgotPasswordResponse: {
      handler(response) {
        if (!response.HasError) {
          this.handleForgotPassword();
          this.handleConfirmResetKeyDialog();
        }
      },
      deep: true,
    },
    confirmResetKeyResponse: {
      handler(response) {
        if (!response.HasError && response.IsValid) {
          this.handleConfirmResetKeyDialog();
          this.handleResetPasswordDialog();
        } else {
          window.sendNotification.error("Onay kodu hatalı!");
        }
      },
      deep: true,
    },
    resetPasswordResponse: {
      handler(response) {
        if (!response.HasError) {
          this.handleResetPasswordDialog();
        }
      },
      deep: true,
    },
  },
  methods: {
    register() {
      if (this.$store.state.User.toggleLoginDialog == true) {
        this.$store.commit("setRegisterHomePageDialog", true);
      } else {
        this.$router.push({ name: "Register" });
      }
    },
    async handleConfirmAccount() {
      this.ConfirmAccountCode = this.otpC1 + this.otpC2 + this.otpC3 + this.otpC4;
      this.ConfirmAccountCode = this.ConfirmAccountCode.toString().toUpperCase();
      const result = await this.$store.dispatch("confirmAccountSignIn", {
        ...this.loginForm,
        Code: this.ConfirmAccountCode,
        IsSupplier: this.$store.getters.loginAsSupplier
      });
      
      if (result === true) {
        this.confirmAccountDialog = false;
      }
    },
    getEmail() {
      let email = localStorage.getItem("email");
      return email ? email : null;
    },
    handleKeydown(e) {
      if (e.key === "Backspace" || e.key === "Delete") {
        if (this.otp4 == null || this.otp4 == "") {
          this.$refs.otp3.focus();
        }

        if (this.otp3 == null || this.otp3 == "") {
          this.$refs.otp2.focus();
        }

        if (this.otp2 == null || this.otp2 == "") {
          this.$refs.otp1.focus();
        }

        if (this.otp1 == null || this.otp1 == "") {
          this.$refs.otp1.focus();
        }
      }
    },
    handleKeydownC(e) {
      if (e.key === "Backspace" || e.key === "Delete") {
        if (this.otpC4 == null || this.otpC4 == "") {
          this.$refs.otpC3.focus();
        }

        if (this.otpC3 == null || this.otpC3 == "") {
          this.$refs.otpC2.focus();
        }

        if (this.otpC2 == null || this.otpC2 == "") {
          this.$refs.otpC1.focus();
        }

        if (this.otpC1 == null || this.otpC1 == "") {
          this.$refs.otpC1.focus();
        }
      }
    },
    handleForgotPasswordRequest() {
      if (this.forgotPasswordForm.PhoneNumber != null && this.forgotPasswordForm.PhoneNumber != "") {
        this.forgotPasswordForm.PhoneNumber = this.$phoneNumberFormatString(this.forgotPasswordForm.PhoneNumber);
      }
      this.$store.dispatch("forgotPasswordRequest", this.forgotPasswordForm);
    },
    handleCheckForgotPasswordRequest() {
      this.otp = this.otp1 + this.otp2 + this.otp3 + this.otp4;
      this.otp = this.otp.toString().toUpperCase();

      this.$store.dispatch("checkForgotPassword", {
        ...this.forgotPasswordForm,
        Code: this.otp,
      });
    },
    handleResetPasswordRequest() {
      this.resetPasswordForm = {
        ...this.resetPasswordForm,
        ...this.forgotPasswordForm,
        ResetKey: this.otp,
      };
      this.$store.dispatch("resetPassword", this.resetPasswordForm);
    },
    handleForgotPassword() {
      this.forgotPasswordDialog = !this.forgotPasswordDialog;
    },
    handleConfirmResetKeyDialog() {
      this.confirmResetKeyDialog = !this.confirmResetKeyDialog;
    },
    handleResetPasswordDialog() {
      this.resetPasswordDialog = !this.resetPasswordDialog;
    },
    async signIn() {

      if (this.rememberMe) localStorage.setItem("phone", this.loginForm.PhoneNumber);
      const result = await this.$store.dispatch("signInUser", this.loginForm);
      if (result === false) this.confirmAccountDialog = true;

      // focus otp input after first req
      this.$nextTick(() => {
        this.$refs.otpC1.focus();
      });

      this.otpC1 = "";
      this.otpC2 = "";
      this.otpC3 = "";
      this.otpC4 = "";
    },
    async handlePressEnter(e) {
      if (e.keyCode === 13) {
        await this.signIn();
      }
    },
  },
  mounted() {
    // window.addEventListener('keyup', (e) => this.handlePressEnter(e));
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
