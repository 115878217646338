import firebase from 'firebase/app'
import 'firebase/firebase-messaging'
import NOTIFICATIONS from "./constants/notifications"
import { createNotification } from "./helpers/generalHelper";

export default async function firebaseInitialize() {
  const firebaseConfig = {
    apiKey: "AIzaSyAZnwhA1PNjt8Ab5ihH8F1KdAQUA0bSChc",
    authDomain: "nakliye-kullan.firebaseapp.com",
    projectId: "nakliye-kullan",
    storageBucket: "nakliye-kullan.appspot.com",
    messagingSenderId: "458246850472",
    appId: "1:458246850472:web:6ac030a28d397ee7cebf9d",
    measurementId: "G-L49EB2M9QK"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig)
  const messaging = firebase.messaging()
  messaging.onMessage(function (payload) {
    const websiteUrl = "https://uygulama.nakliyekullan.com/";

    let isNewAdvert = true;
    const key = Object.keys(payload.data)[0]
    for (let i = 0; i < NOTIFICATIONS.length; i++) {
      let notificationItem = NOTIFICATIONS[0]
      if (notificationItem.Name === key) {
        createNotification(notificationItem.Title, payload.data[key] !== "tr" ? payload.data[key] : notificationItem.Body, websiteUrl)
        isNewAdvert = false;
        break;
      }
    }
    const redirect = "ilanlar/detay?adId="

    if (isNewAdvert) {
      createNotification(LookAtNewAdvert.Title, LookAtNewAdvert.Body, websiteUrl + redirect + key)
    }
  });
}
const LookAtNewAdvert = {
  Name: "LookAtNewAdvert", Title: "İlana göz atın", Body: "İlanınıza göz atın. Görüntülemek için tıklayın.", Redirect: "ilanlar/detay?adId="
};

