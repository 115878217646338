import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
	dealerData: null,
	inviteDealerResponse: { ValidationErrors: [] },
	inviteLink: null,
	invoices: [],
}

export default {
	state,
	getters,
	actions,
	mutations,
}
