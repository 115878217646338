import Vue from 'vue'
import axios from 'axios'
import store from '../store/index'
const clientWithoutToken = axios.create()
const clientWithToken = axios.create()

let token = localStorage.getItem('token')
//giris yapildiginda tokeni guncelle
function updateToken() {
	token = localStorage.getItem('token')
}
function getLoading() {
	return store.getters['selfLoading']
}

function hideLoader() {
	return store.getters['hideLoader']
}

clientWithToken.interceptors.request.use(
	async (config) => {
		if (!getLoading() && !hideLoader()) {
			window.$('#cover-spin').show(0)
			store.commit('loading', true)
		}

		config.headers = {
			Authorization: 'Bearer ' + token,
		}

		config.baseURL = 'https://api.nakliyekullan.com/'
		//config.baseURL = "http://127.0.0.1:25010/"
		return config
	},
	(err) => {
		window.$('#cover-spin').hide(0)

		store.commit('loading', false)

		return Promise.reject(err)
	}
)
clientWithToken.interceptors.response.use(
	(response) => {
		window.$('#cover-spin').hide(0)

		store.commit('loading', false)

		return response
	},
	(err) => {
		window.$('#cover-spin').hide(0)

		store.commit('loading', false)

		return Promise.reject(err)
	}
)

clientWithoutToken.interceptors.request.use(
	async (config) => {
		window.$('#cover-spin').show(0)
		if (!store.getters['selfLoading']) store.commit('loading', true)

		config.baseURL = 'https://api.nakliyekullan.com/'
		//config.baseURL = "http://127.0.0.1:25010/"

		return config
	},
	(err) => {
		window.$('#cover-spin').hide(0)

		store.commit('loading', false)

		return Promise.reject(err)
	}
)

clientWithoutToken.interceptors.response.use(
	(response) => {
		window.$('#cover-spin').hide(0)

		store.commit('loading', false)

		return response
	},
	(err) => {
		window.$('#cover-spin').hide(0)

		store.commit('loading', false)

		return Promise.reject(err)
	}
)

Vue.prototype.$clientWithoutToken = clientWithoutToken
Vue.prototype.$clientWithToken = clientWithToken

export { clientWithToken, clientWithoutToken, updateToken }
