import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import './registerServiceWorker'
import './plugins'
import i18n from "./i18n";
import './helpers'
import store from './store'
import VueApexCharts from 'vue-apexcharts'
import ElButton from './components/button/index'
import {
  VueMaskDirective,
  VueMaskFilter
} from "v-mask";
import VueMeta from 'vue-meta'


Vue.directive("mask", VueMaskDirective);

Vue.filter('VMask', VueMaskFilter);

Vue.use(VueApexCharts)

Vue.use(VueMeta)

Vue.component('apexchart', VueApexCharts)

Vue.component('el-button', ElButton)

global.jQuery = require('jquery')
var $ = global.jQuery
window.$ = $
window.jQuery = global.jQuery
Vue.prototype.$ = global.jQuery


import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCjF3pCyknt07yWnY9N9d9MIPXkyZinAfg',
    libraries: "places"
  },
  installComponents: true,

})

import moment from "moment"
import 'moment/locale/tr'

import "animate.css";

// --------------------

// Element import

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import trLocale from "element-ui/lib/locale/lang/tr-TR";
import locale from 'element-ui/lib/locale';

// ---------------------

// Quill import

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import StarRating from 'vue-star-rating'
Vue.use(StarRating)

Vue.component('StarRating', StarRating)


// ---------------------

// Language sets

const language = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'tr'
locale.use(trLocale)
moment.locale('tr')

// ---------------------

// Vue.use sets

Vue.use(ElementUI, {
  trLocale
})
Vue.use(VueQuillEditor)

// ----------------------


// Prototype sets

Vue.prototype.$moment = moment
// -----------------------
Vue.config.productionTip = false



let vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

window.vm = vm

window.sendNotification = vm.$message;

// setInterval(() => {
//   navigator.geolocation.getCurrentPosition(
//    async (position) => {
//      await store.dispatch("updateUserLocation", {Latitude: position.coords.latitude.toString(), Longitude: position.coords.longitude.toString()});
//    },
//    function (error) {
//      //
//    }
//  );
// }, 10000);

// head.script.push({
//   async: true,
//   src: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
//   dataAdClient: "ca-pub-yourId"
// });