<template>
  <div class="in-wrapper">
    <div class="in-inner">
      <div class="in-step-wrapper" 
        ><span class="in-step" :class="{ active: activeRegister === 1 }"
          >1</span
        >
        <div class="in-text-padding">Tipi</div></div
      >
      <span class="in-line"></span>
      <div  class="in-step-wrapper"  
        ><span class="in-step" :class="{ active: activeRegister === 2 }"
          >2</span
        >
        <div class="in-text-padding">Bilgiler</div></div
      ><span class="in-line"></span>
      <div  class="in-step-wrapper"  
        ><span class="in-step" :class="{ active: activeRegister === 3 }"
          >3</span
        >
        <div class="in-text-padding">Detaylar</div></div
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["activeRegister"],
  methods: {
    path() {
      if (this.emptyName) {
        if (this.emptyName === "none") return "";
        return require("../../assets/images/empty_" + this.emptyName + ".svg");
      } else return require("../../assets/images/empty_" + "offer" + ".svg");
    },
  },
  computed: {
    loading() {
      return this.$store.getters["loading"];
    },
  },
};
</script>
<style scoped>
.in-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 75px;
}
.in-inner {
  display: flex;
  width: 70%;
}
.in-step {
  padding: 5px 12px 5px 12px;
  border-radius: 10px;
  border: 0.1px solid whitesmoke;
  align-self: center;
}
.in-step-wrapper{
  display: flex;
  flex-direction: column;
  /* width: 60px; */
}
.in-step.active {
  -webkit-transition: background-color 600ms linear;
  -ms-transition: background-color 600ms linear;
  transition: background-color 600ms linear;
  background-color: #e9501c;
  border: none;
}
.in-line {
  content: "";
  border-bottom: 1.5px solid whitesmoke;
  padding-bottom: 13px;
  margin-bottom: 35px;
  margin-left: 14px;
  margin-right: 14px;
  position: static;
  display: inline-block;
  width: 100%;
  height: 0.0625rem;
}
.in-text-padding {
  padding-top: 12px;
}
</style>
