<template>
  <button
    class="el-button"
    @click="handleClick"
    :disabled="buttonDisabled || loading"
    :autofocus="autofocus"
    :type="nativeType"
    :class="[
      type ? 'el-button--' + type : '',
      buttonSize ? 'el-button--' + buttonSize : '',
      {
        'is-disabled': buttonDisabled,
        'button-loading': loading,
        'is-plain': plain,
        'is-round': round,
        'is-circle': circle,
      },
    ]"
  >
    <i class="fa fa-spinner fa-spin" v-if="loading"></i>
    <i :class="icon" v-if="icon && !loading"></i>
    <span v-if="$slots.default && !loading"><slot></slot></span>
    <span
      v-else-if="$slots.default"
      :style="{
        'padding-right': padding ? halfLengthOfChildText() * 8 + 'px' : 0,
        'padding-left': padding ? halfLengthOfChildText() * 8 + 'px' : 0,
      }"
      >{{ text }}</span
    >
  </button>
</template>
<script>
export default {
  name: "ElButton",

  inject: {
    elForm: {
      default: "",
    },
    elFormItem: {
      default: "",
    },
  },

  props: {
    type: {
      type: String,
      default: "default",
    },
    size: String,
    icon: {
      type: String,
      default: "",
    },
    nativeType: {
      type: String,
      default: "button",
    },
    loading: Boolean,
    disabled: Boolean,
    plain: Boolean,
    autofocus: Boolean,
    round: Boolean,
    circle: Boolean,
    padding: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      text: "Yükleniyor",
    };
  },
  computed: {
    _elFormItemSize() {
      return (this.elFormItem || {}).elFormItemSize;
    },
    buttonSize() {
      return this.size || this._elFormItemSize || (this.$ELEMENT || {}).size;
    },
    buttonDisabled() {
      return this.disabled || (this.elForm || {}).disabled;
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
    halfLengthOfChildText() {
      let lengthOfChildText = 0;
      this.$slots.default.forEach((el) => {
        if (el.text) lengthOfChildText += el.text.length;
      });
      lengthOfChildText -= this.text.length;
      return lengthOfChildText / 2;
    },
  },
};
</script>
<style scoped>
.button-loading {
  position: relative;
  background-color: gray !important;
  pointer-events: none;
}
</style>