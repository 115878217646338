export default {
	setDealerData(state, payload) {
		state.dealerData = payload
	},
	inviteDealerResponse(state, data) {
		state.inviteDealerResponse = data
	},
	inviteLinkResponse(state, data) {
		state.inviteLink = data
	},
	setInvoices(state, data) {
		state.invoices = data
	},
}
