import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
	/*5 nakliye 6 tedarikçi*/
	token: localStorage.getItem('token'),
	language: localStorage.getItem('lang'),
	user: {
		IsConfirmedAccount: false,
		IsCarrier: null,
	},
	toggleLoginDialog: false,
	loginAsSupplier: false,
	registerHomePageDialog: false,
	loading: true,
	warehouses: [],
	deliveryCompanies: [],
	careerVehicles: null,
	activeRegister: 1,
	companyResponse: { ValidationErrors: [] },
	invoiceResponse: { ValidationErrors: [] },
	loginResponse: { ValidationErrors: [] },
	changePasswordResponse: { ValidationErrors: [] },
	resetPasswordResponse: { ValidationErrors: [] },
	forgotPasswordResponse: { ValidationErrors: [] },
	confirmResetKeyResponse: { ValidationErrors: [] },
	inviteFriendResponse: { ValidationErrors: [] },
	vehicleResponse: {
		ValidationErrors: [],
	},
	warehouseId: null,
	depotResponse: {
		ValidationErrors: [],
	},
	bankResponse: {
		ValidationErrors: [],
	},
	userResponse: {
		ValidationErrors: [],
	},
	invoceResponse: {
		ValidationErrors: [],
	},
	signUpUserInfo: {
		ValidationErrors: [],
	},
	dashboardStatistics: {},
	policies: [],
	systemSettings: {},
	closableAdverts: [],
	closableAdvertsLength: 0,
}

export default {
	state,
	getters,
	actions,
	mutations,
}
