import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
    packets: null,
    nakuHistory: "",
    ThreedHtml: "",
    buyNakuResponse: {
        ValidationErrors: [],
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
