var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"authincation h-100",attrs:{"id":"authentication"}},[_c('div',{staticClass:"container color"},[_c('div',{staticClass:"row justify-content-center h-100 align-items-center"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"authentication-content"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"auth-form"},[_vm._m(0),_c('h1',{staticClass:"text-white",attrs:{"id":"displayReviews"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_vm._m(1),_c('div',{staticClass:"text-center mb-4"},[_c('button',{staticClass:"btn btn-primary btn-block",attrs:{"type":"submit"},on:{"click":() => {
                        _vm.$router.push('/login');
                      }}},[_vm._v(" Hesabın Var Mı? Giriş Yap ")])]),_c('div',{staticClass:"text-center mb-4"},[_c('button',{staticClass:"btn signup-button btn-block sign-up",attrs:{"type":"submit"},on:{"click":() => {
                        _vm.$router.push('/register');
                      }}},[_vm._v(" Ücretsiz Kayıt Ol ")])]),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn signup-button btn-block sign-up",attrs:{"type":"submit"},on:{"click":function($event){_vm.loginWithoutRegisterDialog = true;}}},[_vm._v(" Kayıt Olmadan Devam Et ")])])])])])])])])]),_c('el-dialog',{attrs:{"title":'Kayıt Olmadan Devam Et',"visible":_vm.loginWithoutRegisterDialog,"modal-append-to-body":false},on:{"update:visible":function($event){_vm.loginWithoutRegisterDialog=$event}}},[_c('LoginWithoutRegister',{on:{"close-dialog":function($event){_vm.loginWithoutRegisterDialog = false}}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mb-3"},[_c('a',{staticClass:"logo",attrs:{"href":"https://nakliyekullan.com/"}},[_c('img',{attrs:{"src":require("../../assets/images/whitelogo.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"text-white"},[_vm._v(" Nakliyeci ve Tedarikçiyi bir araya getiren uygulama, nakliyekullan! ")])])
}]

export { render, staticRenderFns }