<template>
  <div>
    <div id="cover-spin"></div>
    <transition :name="transitionName">
      <router-view />
    </transition>
  </div>
</template>

<script>
import firebaseInitialize from "./firebase";
export default {
  name: "App",
  components: {},
  data() {
    return {
      transitionName: "slide-right",
    };
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
    },
  },
  async created() {
    firebaseInitialize();
    await this.$store.dispatch("getPolicies");
    await this.$store.dispatch("getSystemSettings");
  },
};
</script>

<style lang="scss">
// @import './assets/css/';
.policy-dialog img {
  width: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.el-dialog__body {
  color: white !important;
}
#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(114, 112, 112, 0.7);
  z-index: 9999;
  display: none;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: "";
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 170px;
  height: 170px;
  border-style: solid;
  border-color: rgb(238, 140, 27);
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

.avatar-uploader-icon {
  line-height: 178px !important;
}
.cookie-button-reject {
  float: left;
  margin-left: 45px;
  background-color: #d41f3d !important;
  margin-right: 5px !important;
}
.cookie-button {
  float: left;
  margin-left: 45px;
  background-color: #8fd41f !important;
}
.accept-button {
  width: 100% !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  background-color: #8fd41f !important;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.avatar-sm {
  width: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}
.cursor-pointer {
  cursor: pointer;
}
.el-carousel__container {
  position: relative !important;
  height: 350px !important;
}
.apexcharts-legend.position-right {
  width: 150px !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  bottom: 0;
}
.el-slider__button-wrapper {
  z-index: 1 !important;
}
.policy-text {
  text-decoration: underline;
  cursor: pointer;
}
textarea:focus {
  border: none;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.policy-scroll {
  border-radius: 50%;
  border: none;
  height: 40px;
  width: 40px;
  display: inline-block;
  position: absolute;
  right: 20px;
  bottom: 10px;
  z-index: 9999;
}
</style>
