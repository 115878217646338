export default {
    advertsCommit(state, payload) {
        state.ads = payload;
    },
    fastAdvertsCommit(state, payload) {
        state.fastAds = payload;
    },
    singleAdvertCommit(state, payload) {
        state.advert = payload
    },
    adsDialog(state, payload) {
        state.adsDialog = payload
    },
    bidsInfo(state, payload) {
        state.bidsInfo = payload
    },
    shipments(state, payload) {
        state.shipments = payload
    },
    myRates(state, payload) {
        state.myRates = payload
    },
    completedAdverts(state, payload) {
        state.completedAdverts = payload
    },
    advertInfo(state, payload) {
        state.advertInfo = payload
    },
    sendBidResponse(state, payload) {
        state.sendBidResponse = payload
    },
    cancelResponse(state, payload) {
        state.cancelResponse = payload
    },
    bidModal(state, payload) {
        state.bidModal = payload
    },
    ratingDialog(state, payload) {
        state.ratingDialog = payload
    },
}
