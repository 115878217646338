<template>
  <div class="content-body m-0 pt-0">
    <div class="container-fluid">
      <!-- row -->
      <div class="row">
        <div class="col-xl-12 col-xxl-12">
          <div class="card">
            <div class="card-header d-flex justify-content-center">
              <a class="logo" href="javascript:;" @click="() => this.$router.push({ path: '/' })"><img src="../../assets/images/whitelogo.svg" alt=""/></a>
            </div>
            <div class="card-body">
              <div class="row" v-if="activeRegister === 1">
                <div class="col-lg-12 my-4 text-center">
                  <h2 class="text-white">Lütfen kullanım amacınızı seçiniz</h2>
                </div>
                <div class="col-lg-6 my-3" style="min-height: 375px">
                  <div class="mb-3 text-center flex-column">
                    <a
                      id="supplier"
                      href="javascript:;"
                      class="
                        d-flex
                        white-bg
                        flex-column
                        justify-content-center
                        align-items-center
                      "
                      @click="
                        () => {
                          isCarrier = false;
                        }
                      "
                    >
                      <img src="../../assets/images/tedarikci.svg" alt="" style="max-width: 300px; width: 100%; height: 300px" />
                    </a>
                    <span class="mb-3 text-white">Nakliye için taşıta ihtiyacım var.</span>
                  </div>
                </div>
                <div class="col-lg-6 my-3" style="min-height: 375px">
                  <div class="mb-3 text-center">
                    <a
                      id="carrier"
                      href="javascript:;"
                      class="
                        d-flex
                        white-bg
                        flex-column
                        justify-content-center
                        align-items-center
                      "
                      @click="
                        () => {
                          isCarrier = true;
                        }
                      "
                    >
                      <img src="../../assets/images/nakliyeci.svg" alt="" style="max-width: 300px; width: 100%; height: 300px" />
                    </a>
                    <span class="mb-3 text-white">Nakliye yapabilirim.</span>
                  </div>
                </div>
              </div>

              <el-button v-if="activeRegister === 1" class="bg-secondary py-3" style="margin-top: 12px; float: left" @click="$emit('close-dialog')">Geri Dön</el-button>
              <el-button class="bg-primary py-3" style="margin-top: 12px; float: right" @click="routeWithoutRegister" :disabled="activeRegister === 1 && isCarrier == null">Sonraki Adım</el-button>
              <el-button v-if="activeRegister === 3" class="bg-secondary py-3" style="margin-top: 12px; float: left" @click="navigateToHomePage">Bu Adımı Geç</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import POLICIES from "../../constants/policies";
import META_DATA from "../../constants/metaData";

export default {
  components: {},
  metaInfo() {
    return {
      title: META_DATA.REGISTER.Title,
    };
  },
  name: "Register",
  data() {
    return {
      imageUrl: "",
      isCarrier: null,
      cities: null,
      districts: null,
      Form: {
        FirstName: null,
        LastName: null,
        PhoneNumber: null,
        Email: null,
        Password: null,
        PasswordRetry: null,
        CityId: null,
        DistrictId: null,
        TCKimlikNo: null,
        InvitationCode: null,
      },
      BillForm: {
        CompanyLogo: null,
        Title: null,
        Email: null,
        PhoneNumber: null,
        CityId: null,
        DistrictId: null,
        TaxNo: null,
        TaxOffice: null,
        Address: null,
      },
      locationDialog: false,
      kvkkDialog: false,
      confidentialityAgreementDialog: false,
      userAgreementDialog: false,
      registerPolicies: {
        kvkk: false,
        userAgreement: false,
      },
      hasInvitationCode: false,
    };
  },
  async beforeMount() {
    
  },
  mounted() {
    const currentInstance = this;
    this.$(".policy-scroll").click(function(e) {
      const height = currentInstance.getHeight();
      $("#kvkk-policy, #user-policy").animate({ scrollTop: height }, "slow");
      return false;
    });
  },
  methods: {
    routeWithoutRegister() {
      const user = !!this.$store.state.User.token;
      this.$store.commit("setIsCarrier", this.isCarrier);

      // assign current role
      const role = this.isCarrier ? "nakliyeci" : "tedarikci";
      // if current state is guest create url query - if user do not
      const query = !user ? { misafirRol: role } : {};

      this.$router.push({ name: "ilanlar.aktif", query: query });
    },
    handleDialogOpen() {
      this.$(".v-modal")[0].remove();
    },
    getHeight() {
      console.log("this.kvkkDialog: ", this.kvkkDialog);
      return this.kvkkDialog ? this.$("#kvkk-policy-inside").height() : this.$("#user-policy-inside").height();
    },
    handleCheckbox(e) {
      window.sendNotification.warning("Onaylamak için okumanız gerekmektedir");
      e.preventDefault();
      e.stopPropagation();
    },
    updateTCKimlikNo(e) {
      this.Form.TCKimlikNo = e.replace(/[^\d]/g, "");
    },
    showPassword() {
      const passwordType = this.$("#password").attr("type");
      if (passwordType === "text") {
        this.$("#togglePassword").removeClass("bi-eye");
        this.$("#togglePassword").addClass("bi-eye-slash");
        this.$("#password").attr("type", "password");
      } else if (passwordType === "password") {
        this.$("#togglePassword").removeClass("bi-eye-slash");
        this.$("#togglePassword").addClass("bi-eye");
        this.$("#password").attr("type", "text");
      }
    },
    showPasswordRetry() {
      const passwordRetryType = this.$("#passwordRetry").attr("type");
      if (passwordRetryType === "text") {
        this.$("#togglePasswordRetry").removeClass("bi-eye");
        this.$("#togglePasswordRetry").addClass("bi-eye-slash");
        this.$("#passwordRetry").attr("type", "password");
      } else if (passwordRetryType === "password") {
        this.$("#togglePasswordRetry").removeClass("bi-eye-slash");
        this.$("#togglePasswordRetry").addClass("bi-eye");
        this.$("#passwordRetry").attr("type", "text");
      }
    },
    handleAcceptKvkk() {
      this.registerPolicies.kvkk = true;
      this.kvkkDialog = false;
    },
    handleAcceptLocation() {
      this.registerPolicies.location = true;
      this.locationDialog = false;
    },
    handleAcceptUserAgreement() {
      this.registerPolicies.userAgreement = true;
      this.userAgreementDialog = false;
    },
    handleAcceptConfidentialityAgreement() {
      this.registerPolicies.confidentialityAgreement = true;
      this.confidentialityAgreementDialog = false;
    },
    back() {
      this.isCarrier = null;
      this.districts = null;
      this.activeRegister--;
      this.resetResponses();
    },
    goBackToApp() {
      this.$router.push({ path: "/" });
    },
    getToken() {
      return localStorage.getItem("token");
    },
    async signUp() {
      var req = this.Form;
      if (req.PhoneNumber != null && req.PhoneNumber != "") {
        req.PhoneNumber = this.$phoneNumberFormatString(req.PhoneNumber);
      }
      this.$store.dispatch("signUpUser", {
        isCarrier: this.isCarrier,
        Form: req,
      });
    },
    async addBillDetails() {
      var req = this.BillForm;
      if (req.PhoneNumber != null && req.PhoneNumber != "") {
        req.PhoneNumber = this.$phoneNumberFormatString(req.PhoneNumber);
      }
      this.$store.dispatch("addBillDetails", req);
    },
    next() {
      this.districts = null;
      if (this.activeRegister === 2) {
        if (this.registerPolicies.kvkk && this.registerPolicies.userAgreement) this.signUp();
        else window.sendNotification.error("Devam etmek için sözleşmeleri kabul edin!");
      } else if (this.activeRegister === 3) {
        this.addBillDetails();
      } else {
        this.activeRegister++;
      }
    },
    handleAvatarSuccess(res) {
      if (res.HasError) {
        window.sendNotification.error(res.Message);
      } else {
        window.sendNotification.success(res.Message);
        this.BillForm.CompanyLogo = res.Data.Url;
      }
    },
    navigateToHomePage() {
      this.$router.push({ path: "/anasayfa" });
    },
    resetResponses() {
      this.companyResponse = { ValidationErrors: [] };
      this.signUpUserResponse = { ValidationErrors: [] };
      this.Form = {
        FirstName: null,
        LastName: null,
        PhoneNumber: null,
        Email: null,
        Password: null,
        PasswordRetry: null,
        CityId: null,
        DistrictId: null,
        TCKimlikNo: null,
        InvitationCode: null,
      };
      this.BillForm = {
        CompanyLogo: null,
        Title: null,
        Email: null,
        PhoneNumber: null,
        CityId: null,
        DistrictId: null,
        TaxNo: null,
        TaxOffice: null,
        Address: null,
      };
    },
  },
  watch: {
    hasInvitationCode(val, old) {
      if (!val) this.Form.InvitationCode = "";
    },
    isCarrier(val) {
      if (val) {
        $("#carrier").addClass("select-active");
        $("#supplier").removeClass("select-active");
      } else {
        $("#carrier").removeClass("select-active");
        $("#supplier").addClass("select-active");
      }
    },
    // "Form.CityId": async function (val) {
    //   this.districts = await this.$getDistricts(val);
    //   this.Form.DistrictId = null;
    // },
    // "BillForm.CityId": async function (val) {
    //   this.districts = await this.$getDistricts(val);
    //   this.BillForm.DistrictId = null;
    // },
  },
  destroyed() {
    this.activeRegister = 1;
    this.resetResponses();
  },
  computed: {
    locationInfo: {
      get() {
        const cookie = this.$store.getters["policies"].find((policy) => policy.Type === POLICIES.LOCATION_DATA);
        return cookie ? cookie : { Title: "", Content: "" };
      },
    },
    kvkkInfo: {
      get() {
        const cookie = this.$store.getters["policies"].find((policy) => policy.Type === POLICIES.KVKK);
        return cookie ? cookie : { Title: "", Content: "" };
      },
    },
    userAgreementInfo: {
      get() {
        const cookie = this.$store.getters["policies"].find((policy) => policy.Type === POLICIES.USER_AGREEMENT);
        return cookie ? cookie : { Title: "", Content: "" };
      },
    },
    confidentialityAgreementInfo: {
      get() {
        const cookie = this.$store.getters["policies"].find((policy) => policy.Type === POLICIES.CONFIDENTIALITY);
        return cookie ? cookie : { Title: "", Content: "" };
      },
    },
    activeRegister: {
      get() {
        return this.$store.getters["activeRegister"];
      },
      set(newValue) {
        this.$store.commit("activeRegister", newValue);
      },
    },
    companyResponse: {
      get() {
        return this.$store.getters["companyResponse"];
      },
      set(newValue) {
        this.$store.commit("companyResponse", newValue);
      },
    },
    signUpUserResponse: {
      get() {
        return this.$store.getters["signUpUserInfo"];
      },
      set(newValue) {
        this.$store.commit("signUpUserInfo", newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
